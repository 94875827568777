.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    height: 100vh;
    width: 100%;
}
.row{
    margin-bottom: 1rem;
}
.hide-login{
    display: none !important;
}
.fist-f {
    padding: 50px 30px;
    background: #fff;
    border-radius: 7px;
    /* max-width: 320px; */
    width: 320px;
}
label{
    color: #707070;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px !important;
}
.form-control {
    padding: 15px 14px !important;
}
.login-button {
    background: #004A87;
    justify-content: center;
    border: 0px;
    margin: 0px 0px 1.75rem;
    cursor: pointer;
    vertical-align: middle;
    font-size: 0.9375rem;
    letter-spacing: 0.4px;
    color: rgb(255, 255, 255);
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    font-weight: 500;
    border-radius: 8px;
    box-shadow: rgb(76 78 100 / 42%) 0px 4px 8px -4px;
    line-height: 1.5;
    padding: 0.5rem 1.375rem;
}
.fist-f img {
    max-width: 100%;
}