.loading-request{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #00000060;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 99999;
}

.loading-request.show{
	display: flex;
}

.loading-request .rotate{
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}



