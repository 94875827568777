.language-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.language-select .selected.option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.language-select .selected.option img {
  width: 17px;
  height: 17px;
}

.language-select .selected.option span {
  margin-left: 4px;
  font-size: 12px;
}

.language-select .options {
  display: none;
  position: absolute;
  margin-top: 10px;
  background-color: #FFF;
  padding: 8px;
  z-index: 99;
}

.language-select .options .option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  color: #333;
}

.language-select .options .option img {
  width: 17px;
  height: 17px;
}

.language-select .options .option span {
  margin-left: 4px;
  font-size: 12px;
}

.language-select.open .options {
  display: block;
}