.description {
    color: #80909f;
    max-width: 35rem;
    text-align: center;
    margin: 1.8rem auto;
    border-bottom: 2px solid #e6ecf1;
    border-top: 2px solid #e6ecf1;
    padding: 10px 0px 10px 0px;
}
.footer p {
    color: #80909f;
}