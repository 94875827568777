.body-details .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px;
}
.form-prd {
    max-width: none;
    padding: 25px 5px;
    
}
.form-d {
    padding: 6px 12px;
  
}