.chat-sidebar {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    width: 40%;
    height: 600px;
}
.users-list {
    list-style: none;
}
.users-list {
    list-style: none;
    border-radius: 0.25rem;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 10px
}
li.user-itm {
    display: flex;
    padding: 15px 15px;
    display: -webkit-flex;
    cursor: pointer;
    margin-bottom: 3px;
    border: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 3px;
    position: relative;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
}
.chat-conversation {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    width: 60%;
    background: #fafcff;
    height: 100%;
    padding: 0px 10px 0px 15px;
}
.chat-conversation-hidden{
    display: none !important;
}
.chat-list {
    flex: 1;
    overflow: auto;
    padding: 10px 25px;
    width: 100%;
    height: 600px;
}
.newmesg {
    width: 40px;
    height: 40px;
    background-color: #009688;
    border-radius: 30px;
    color: #fff;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    border: none;
}
.chat-header {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 12px 15px;
    align-items: center;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 5px;
    background: #FFFFFF;
    border: 1px solid #F4EEFF;
    margin-top: 10px;
    position: relative;
}
.user-details {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
}
.user-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.user-img {
    margin-right: 0.8rem;
    display: inline-block;
    margin-bottom: 0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    position: relative;
    background: #054f7d;
    line-height: 38px;
    color: white;
}
small.online:before {
    content: "";
    position: absolute;
    display: block;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    bottom: 4px;
    left: -12px;
    border: 2px solid #fff;
    z-index: 9;
    background-color: #34D859;
    margin-right: 5px;
}
.online {
    position: relative;
    margin-left: 10px;
}
.conversation-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 700px;
    /* overflow: auto; */
}
.conversation-details {
    /* overflow: auto; */
    width: 100%;
    height: 100%;
}

/* custom scrollbar */
.chat-body::-webkit-scrollbar , .chat-list::-webkit-scrollbar {
    width: 20px;
  }
  
  .chat-body::-webkit-scrollbar-track , .chat-list::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .chat-body::-webkit-scrollbar-thumb, .chat-list::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  .chat-body::-webkit-scrollbar-thumb:hover ,.chat-list::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }


.chat-body {
    flex: 1;
    padding: 40px 0px 20px 0px;
    /* padding-bottom: 90px; */
    max-height: 80%;
    overflow: auto;
}
.userchats .inner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.options-chat {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 600px;
}
.users-select .css-1s2u09g-control, .users-select.css-1pahdxg-control {
    height: auto;
    min-height: 56px;
    border-radius: 50px;
}
.new-msg-area {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    position: relative;
    margin: 0 10px;
    background: #fff;
    border-radius: 50px;
    border: 1px solid #999;
    transition: all .1s linear;
}
.new-msg-area:focus-within{
    box-shadow: 0px 0px 10px 3px #2184c180;
}
.new-msg-area input.chat_form {
    border-radius: 50px;
    background: transparent;
    border: none;
    padding: 15px;
    width: calc(100% - 30px - 100px);
}
.new-msg-area input.chat_form:focus, .new-msg-area input.chat_form:hover, .new-msg-area input.chat_form:active, .new-msg-area input.chat_form:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none;
}
.form-buttons {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
button.btn.send-btn {
    background: #2196f3;
    border-radius: 50px;
    color: #fff;
    max-width: 100px;
}
input.form-control.chat_form {
    border-radius: 50px;
    background: transparent;
    border: none;
}
.chat-footer {
    position: relative;
    padding: 0px 0px 15px;
    /* position: fixed;
    bottom: 0;
    width: inherit;
    right: 0; */
}
.chat-footer .return-inner{
    position: absolute;
    width: 100%;
    bottom: 70px;
    left: 0;
}
.msgs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
}
.chat-body .msgs > div{
    width: 100%;
}
.msg-your {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 80%;
    width: 100%;
    margin-left: auto;
}
.msg-name {
    text-align: left;
}
.msg-recipient-wrapper{
    width: 100%;
    max-width: 80%;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.msg-recipient {
    width: 100%;
    max-width: 80%;
    margin-right: auto;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
}
.msg-recipient .msg-inner {
    background-color: #09304e;
    border-radius: 20px 20px 20px 0;
}
.msg-your .msg-inner {
    background-color: #0a94ff;
    border-radius: 20px 20px 0 20px;
}
.msg-inner {
    padding: 14px 20px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-align: start;
}
.msg-recipient.msg-recipient-with-reply .msg-inner{
    border-radius: 0 20px 20px 20px;
}
.msg-your.msg-your-with-reply .msg-inner{
    border-radius: 20px 0 20px 20px;
}
button.readit {
    border-radius: 50px;
    color: #fff;
    margin-left: 5px;
}
.msg-preview {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
.msg-preview.msg-preview-bold{
    font-weight: 600;
}
ul.dropdown-menu.show {
    background: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgb(200 198 198 / 25%);
    border-radius: 4px;
}
.ul.dropdown-menu.show li {
    padding: 7px 14px;
}
.notification-unread {
    width: 20px;
    height: 20px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    line-height: 0;
    font-size: 11px;
    background-color: #4caf50;
    color: #fff;
    border-radius: 50%;
    margin-left: auto;
    margin-top: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}
.userchats {
    flex: 1;
    position: relative;
    min-width: 0px;
    display: flex;
}
.notif {
    position: relative;
}
ul.dropdown-menu.show li {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
@media only screen and (max-width:320px)
{
    .bar-machine.navbar-expand {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width:425px)
{   
    .chat-sidebar {
        width: 100%;
    }
    .chat-conversation {
        width: 100%;
    }
    .chatpage {
        flex-wrap: wrap;
    }
    .hide-sidebar, .chat-conversation {
        display: none;
    }
    .show-chatbar {
        display: block;
    }

}
@media only screen and (min-width:426px) {
    .back {
        display: none;
    }
}
.return-inner {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 10px;
    width: 97%;
    color: #837a7a;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 5px;
    background: #FFFFFF;
    border: 1px solid #F4EEFF;
}
.cancel-returnmsg {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: #09304e;
    border-radius: 50%;
    color: #fff;
    right: 0;
}
.return-inner .returnto, .return-inner .returntomsg{
    width: 100%;
    text-align: left;
}

.msginner {
    background-color: #efefef;
    border-radius: 50px;
    min-width: 90px;
    padding: 10px;
}

.replymsg.recipient-replymsg .msginner {
    border-radius: 20px 20px 20px 0;
}

.replymsg.your-replymsg .msginner {
    border-radius: 20px 20px 0 20px;
}
.replymsg .answer-top{
    font-size: 13px;
}
.msg-name h6{
    font-size: 0.8rem;
}
.time-ago{
    font-size: 12px !important;
    color: #777;
}