.body-details .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px;
}
body {
    background-color: #F7F7F9 !important;
}
.pd0 {
    padding: 0px !important;
} 
.form-prd {
    max-width: none;
    padding: 25px 5px;
    /* display: flex; */
    /* flex-wrap: wrap; */
}
.form-d {
    /* flex-grow: 1; */
    padding: 6px 12px;
    /* display: inline-flex; */
    /* flex-direction: column; */
}
/* .flex-form {
    display:flex;
} */
.form-d label , .form-prd label {
    display: block !important;
}
.flatpickr-input {
    height: 56px;
    border: 1px solid #cccccc;
    width: 100%;
    border-radius: 5px;
    text-align: center;
}
.bcolor {
    color: #054f7d;
}
.login-button {
    width: auto;
}
.checkboxes-commands label {
    float: left;
    display: flex !important;
    align-items: center;
    width: 100%;
    line-height: 2em;
    cursor: pointer;
}
.flex-item label input {
    position: absolute;
    display: none;
    color: #fff !important;
}
.flex-item {
    margin: 4px;
    background-color: #99b3c9;
    border-radius: 50px;
    border: none;
    overflow: hidden;
    float: left;
    /* min-width: 250px; */
    min-width: 240px;
    display: flex;
    justify-content: center;
    max-width: 500px;
    min-height: 50px;
    flex-grow: 1;
    box-shadow: 5px 5px 2px -2px #cbcaca;
}
.flex-item label span {
    display: flex;
    width: 100%;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.checkboxes-commands .flex-item label input + span {
    font-size: 18px;
    line-height: normal;
}
.flex-item label input + span {
    color: #fff;
   
}
.flex-item input:checked + span {
    color: #fff !important;
   
}
.flex-item input:checked + span {
    background-color: #004a87;
}
.checkboxes-commands {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 5px;
}
.checkboxes-processes label {
    float: left;
    display: flex !important;
    align-items: center;
    width: 100%;
    line-height: 2em;
    cursor: pointer;
}
.checkboxes-processes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.checkboxes-commands::-webkit-scrollbar {
 /* width: 1em; */
    height: 10px;
}
  
.checkboxes-commands::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .checkboxes-commands::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004a87;
  }
  
  .checkboxes-commands::-webkit-scrollbar:vertical {
    display: none;
  }
  .command-choices {
    background-color: #fff;
    padding: 10px 0px 0px 0px;
    position: relative;
  }
 .rdt_TableCol {
    background-color: #f5f5f7;
    color: #707070;
    font-size: 15px;
 }
 .rdt_TableCol:not(:first-child):before { /*nth-child(-n + 2)*/
    content: '';
    border-left: 2px solid #e0e0e5;
    display: block;
    height: 20px;
    margin-right: 5px;
 }
 .rdt_TableCell:nth-child(2) {
    color: #004a87;
    font-weight: 600;
 }
 .rdt_TableCell {
    font-size: 16px;
    color: #707070;
    padding: 10px 0px;
    font-size: 18px;
 }
 
 .App input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}
input#search {
    height: 38px;
    border: 1px solid #cccccc;
}
.search-button {
    height: 38px;
    background: #004a87;
    color: white;
    border: 1px solid #004a87;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.jWKhbc {
    background: #f5f5f7;
}
.bordbotm {
    border-bottom: 1px solid #e0e0e0;
    padding: 5px 0px;
}
.pd-5 {
    padding: 5px;
    color: #707070;
}
.process-t input, .scrap-products input {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
    padding: 8px 2px;
    overflow: hidden;
    background: transparent;
}
.modal-90w {
    max-width: 90% !important;
}
.process-t input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.process-t input[type=number] {
  -moz-appearance: textfield;
}
.imgmodal {
    object-fit: contain;
    max-height: 80vh;
    width: 100%;
    min-height: 200px;
    position: relative;
    /* height: 80vh; */
}
.rdt_TableRow:nth-of-type(2n) {
    background: #4c4e640d;
}
.new-scrap .css-1s2u09g-control , .new-scrap .css-1pahdxg-control{
    height: 56px;
}
.wasteselect {
    height: 56px;
}
.mb-6 {
    margin-bottom: 5rem;
}
.checkboxes-processes .flex-item {
    background-color: unset;
    box-shadow: none;
    min-height: auto;
}
.checkboxes-processes .flex-item label input + span {
    color: #707070;
  
}
.bMgaAx div:first-child {
    white-space: pre-wrap !important;
    overflow: unset !important;
}
.rdt_TableHeader > div:first-child  {
    display: none;
}
.sc-hKMtZM.sc-bBrHrO.cLRkKo.sWLRd {
    background: #f5f5f7;
}
.bMgaAx div:first-child {
    white-space: pre-wrap !important;
    overflow: unset !important;
}
.error-outcome {
    background: #df0e0e;
    color: #fff;
    border-radius: 7px;
}
.checkboxes-processes .flex-item input:checked + span {
    background: #ffffff00;
    color: #000 !important;
}
.checkboxes-processes .flex-item {
    min-height: 0 !important;
    height: 30px;
}
.extra-data-tbl > div > div {
    text-align: left;
}
.rdt_Table div {
    white-space: unset !important;
}
.table-row-counter {
    text-align: right;
    color: #7a7a7a;
    padding: 10px;
    background: #fff;
    border-top: 1px solid #e0e0e0;
    font-size: 14px;
}
@media screen and (max-width:600px){
    input#search {
        height: 38px;
        border: 1px solid #cccccc;
        width: 120px;
    }
    .checkboxes-commands .flex-item label input + span {
        font-size: 14px;
        line-height: normal;
    }
    label {
        font-size: 15px;
    }
    .checkboxes-processes .flex-item {
        height: 20px;
    }
    .rdt_TableCol {
        font-size: 12px;
    }
    .rdt_TableCell {
        font-size: 15px;
    }
}
.text-right {
    width: 100%;
    text-align: right;
}
.process-t #cell-2-undefined {
    text-align: left !important;
}
.process-t [data-column-id="3"] , .process-t [data-column-id="4"] {
    padding-left: 10px !important;
    padding-right: 0px !important;
}
.process-t #cell-3-undefined, .process-t #cell-4-undefined {
    padding-right: 0px !important;
    padding-left: 10px !important;
}
.textcenter {
    width: 100%;
    text-align: center;
}
.modalconsumes .row .rdt_TableCell:nth-child(2) {
    color: #707070;
    font-weight: normal;
}