.float{
	position:fixed;
	/* width:60px; */
	height:60px;
	bottom:5px;
	right:5px;
	background-color:#2196f3;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    border: none;
    padding: 0 10px;
}

.my-float{
	margin-top:22px;
}
.notif-msg {
	width: 20px;
    height: 20px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    line-height: 0;
    font-size: 11px;
    background-color: #4caf50;
    color: #fff;
    border-radius: 50%;
    margin-left: auto;
    margin-top: 10px;
    position: absolute;
    right: 0;
    top: -10px;
}
a.float{
    display: flex;
    align-items: center;
}
.footer-unread-msgs{
    position: fixed;
    bottom: 70px;
    right: 5px;
    color: #4c4c4c;
    text-align: center;
    border: none;
    padding: 0 10px;
    max-height: calc(100% - 300px);
    overflow: auto;
}
.footer-unread-msgs .footer-unread-msg{
    background: #09304e;
    margin: 10px;
    margin-right: 0;
    padding: 10px;
    text-align: left;
    min-width: 250px;
    max-width: 400px;
    color: #fff;
    border-radius: 7px;
    border-top-right-radius: 0;
}
.footer-unread-msgs .footer-unread-msg .msg-pre-text{
    font-size: 12px;
}
.footer-unread-msgs .footer-unread-msg button{
    display: block;
    border: 2px solid #fff;
    border-radius: 7px;
    color: #fff;
    background: none;
    /* padding: 3px; */
    margin-top: 10px;
    font-size: 15px;
}
.footer-unread-msgs .message-actions {
    display: flex;
    justify-content: space-between;
}
