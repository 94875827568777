.topbar {
    border-bottom: 1px solid #e7e7e7;
    flex-wrap: nowrap !important;
}

.nav-link {
    font-size: 18px;
    font-weight: 600;
}
.navbar-text {
    font-size: 14px;
}
.header-wrapper {
    box-shadow: rgb(187 187 187 / 42%) 0px 4px 8px -4px;
}
.changebutton a {
  text-decoration: none;
}
.btn-success.active {
    background-color: #13653f !important;
    border-color: #13653f !important;
}
.logout-btn {
    border: 1px solid #054f7d;
    border-radius: 7px;
    margin: 5px;
    background: #054f7d;
    color: #fff;
}
/* * {
    box-sizing: border-box; }
   */
.machinestatus {
  display: none; 
}
  
.machinestatus + label {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 35px;
  background: linear-gradient(to right, #fd1a15 0, #fc8b34 45%, #7fd03e 55%, #007177 100%) 0 0;
  background-size: 225px 100%;
  border-radius: 70px;
  padding: 7px;
  cursor: pointer;
  transition: all 500ms ease;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2) inset; 
  min-width: 100px;
}
.machinestatus + label:before {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  left: 65px;
  top: 2px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
  font-weight: bold;
  color: #fd1a15;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: left 500ms ease, color 500ms ease, transform 150ms ease; }
.machinestatus + label:active:before {
  transform: scale(0.95); 
}
  
.machinestatus:checked + label {
  background-position-x: -350px; 
}
  .machinestatus:checked + label:before {
    content: '';
    color: #007177;
    left: 5px; 
}
.machinestatus:checked + label:after {
  content: 'ΟΝ';
  color: #efefef;
  font-weight: 600;
  position: absolute;
  top: 2px;
  right: 25px;
}
.machinestatus + label:after {
  content: 'OFF';
  color: #efefef;
  font-weight: 600;
  position: absolute;
  top: 2px;
  left: 30px;
}
.bar-machine {
  background-color: #09304e;
  color: white;
}
.machine-wrp span:not(:last-child) {
  border-right: 1px solid gray;
  padding: 0px 4px;
}
.machine-wrp span {
  font-size: 12px;
}
.menu-choices {
  margin-left: 10px;
}
.menu-elem{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width:600px)
 {
  .machine-wrp, .menubar, .topbar, .navbar-expand{
    flex-wrap: wrap !important;
  }
  .menu-elem{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-start;
  }
  .machinestatus + label {
    width: 91px;
    height: 26px;
    min-width: 91px;
  }
  .machinestatus + label:before {
    content: '';
    width: 22px;
    height: 22px;
  }
  .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
 }
 @media screen and (min-width:600px) and (max-width:992px) {
  .flx, .machine-wrp {
    flex-wrap: wrap;
    justify-content: center;
  }
 }
 .mr-1 {
  margin-right: 10px;
 }
 .menubar .btn-success {
  background-color: #198754;
  border-color: #198754;
 }